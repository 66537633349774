.element {
  display: flex;
  text-decoration: none;
  color: inherit;
  outline: none;
  align-self: center;
  height: 35px;
  padding: 5px;
  outline: none
}

.right {
  right: 0;
  padding-right: 1rem;
}

.sidenav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
  background-color: white;
}

.modal-dialog {
  margin: 0 !important;
}

.list-item {
  padding-bottom: 1.5rem;
  font-family: 'Muli-Bold';
}
