.bg-color {
  background-color: #FFEEDF;
  height: 80vh;
}

.screenshot-image {
  height: 100%;
  width: auto;
}

.screenshot-text {
  font-size: 20px;
}

.text-container {
  padding: 3rem;
  text-align: center;
}

.second-col {
  margin-top: 7rem;
}
