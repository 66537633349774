.title {
  padding: 5vw 20vw 5vw 20vw !important;
  /* padding-right: 200px !important;
  padding-left: 200px !important; */
  font-family: 'Muli-Bold';
  font-style: italic;
  font-size: 2rem;
}

.main-image {
  width: 100%;
  height: 100%;
}
